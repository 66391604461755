import styled from 'styled-components';

export const Navbar = styled.div`
  background: black;
  position: relative;
  z-index: 99;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #e7ddbb;

  > div {
    max-width: 85%;
    margin: auto;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 1500px) {
      max-width: 90%;
    }

    @media (max-width: 1440px) {
      max-width: 85%;
    }

    @media (max-width: 1270px) {
      max-width: 100%;
      padding: 15px 20px;
    }

    @media (max-width: 550px) {
      padding: 10px 20px;
    }
  }

  .Logo {
    position: relative;
    width: 120px;
    height: auto;

    @media (max-width: 1440px) {
      width: 100px;
    }

    @media (max-width: 500px) {
      width: 80px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .hamburger {
    display: none;
    padding-right: 0;

    @media (max-width: 1000px) {
      display: inline-block;
    }

    .hamburger-inner,
    .hamburger-inner:before,
    .hamburger-inner:after {
      background-color: #e7ddbb;
    }
  }

  #content {
    display: flex;
    flex-direction: column;
    transition: all ease 0.3s;

    @media (max-width: 1000px) {
      position: absolute;
      top: ${(props) => (props.isOpen ? 'calc(100% + 1px)' : '-500px')};
      width: 100%;
      left: 0;
      flex-direction: column-reverse;
      padding: 10px 20px 20px 20px;
      background-color: #000;
    }
  }

  .header-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 740px) {
      flex-direction: column-reverse;
    }

    > div {
      display: flex;

      @media (max-width: 740px) {
        margin: 10px 0;
      }
    }

    .endereco {
      margin-right: 25px;
      line-height: 20px;
    }

    .email {
      margin-left: 25px;
    }

    a {
      text-decoration: none;
      font-size: 14px;
      line-height: 28px;
      color: #e7ddbb;
      transition: all ease 0.5s;
      display: flex;
      align-items: center;
      font-weight: 300;
      text-align: center;

      svg {
        margin-right: 10px;

        @media (max-width: 490px) {
          height: 35px;
          width: auto;
        }
      }

      img {
        margin: 0 5px;
      }
    }

    a:hover {
      opacity: 0.4;
    }
  }

  .header-bottom {
    margin-top: 30px;
    background-color: black;

    @media (max-width: 1440px) {
      margin-top: 15px;
    }

    @media (max-width: 1000px) {
      margin-top: 0;
      margin-bottom: 15px;
    }

    ul {
      display: flex;
      list-style: none;
      justify-content: flex-end;
      align-items: center;
      margin: 0;

      @media (max-width: 820px) {
        flex-direction: column;
      }

      .tel {
        margin: 0 0 0 20px;
        padding: 5px 0px 5px 50px;
        border-left: 1px solid #e7ddbb;
        display: flex;

        @media (max-width: 1440px) {
          padding: 5px 0px 5px 40px;
        }

        @media (max-width: 1140px) {
          margin: 0 0 0 10px;
          padding: 5px 0px 5px 20px;
        }

        @media (max-width: 820px) {
          border-left: none;
          border-top: 1px solid #e7ddbb;
          padding: 10px 20px;
          margin-top: 10px;
        }

        svg {
          height: 32px;
          width: auto;
          margin-left: 25px;

          @media (max-width: 1440px) {
            height: 26px;
          }
        }

        a {
          padding: 0;
          font-size: 22px;
          line-height: 28px;
          color: #e7ddbb;
          font-weight: 300;
          flex-direction: row;
          align-items: flex-end;
          transition: all ease 0.5s;

          @media (max-width: 1440px) {
            font-size: 20px;
            line-height: 26px;
          }

          :after {
            display: none;
          }

          span {
            font-size: 18px;
            line-height: 24px;
            margin-right: 5px;

            @media (max-width: 1440px) {
              font-size: 16px;
              line-height: 24px;
            }
          }

          :hover {
            opacity: 0.4;
          }
        }
      }

      li {
        margin: 0 20px;

        @media (max-width: 1140px) {
          margin: 0 10px;
        }

        @media (max-width: 820px) {
          margin: 10px 0;
        }

        a {
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color: #c3bdac;
          padding: 0 10px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media (max-width: 1440px) {
            font-size: 16px;
            padding: 0 5px;
            line-height: 22px;
          }

          :after {
            width: 0%;
            height: 4px;
            background-color: #e7ddbb;
            content: '';
            position: absolute;
            bottom: -10px;
            transition: all ease 0.5s;
          }

          :hover {
            :after {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

export const FixedNavbar = styled.header`
  background-color: #000;
  padding: 15px 20px;
  border-bottom: 1px solid #e7ddbb;
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  width: 100%;

  @media (max-width: 1010px) {
    z-index: ${(props) => (props.isOpen ? '99' : '98')};
  }

  @media (max-width: 550px) {
    padding: 10px 20px;
  }

  > div {
    max-width: 85%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1500px) {
      max-width: 90%;
    }

    @media (max-width: 1440px) {
      max-width: 85%;
    }

    @media (max-width: 1345px) {
      max-width: 100%;
    }

    .Logo {
      width: 100px;
      height: auto;
      display: flex;

      @media (max-width: 500px) {
        width: 80px;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .hamburger {
      display: none;
      padding-right: 0;

      @media (max-width: 1010px) {
        display: inline-block;
      }

      .hamburger-inner,
      .hamburger-inner:before,
      .hamburger-inner:after {
        background-color: #e7ddbb;
      }
    }

    nav {
      @media (max-width: 1010px) {
        position: absolute;
        top: ${(props) => (props.isOpen ? 'calc(100% + 1px)' : '-500px')};
        background-color: #000;
        width: 100%;
        left: 0;
        padding: 15px 20px;
      }

      ul {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;

        @media (max-width: 1010px) {
          justify-content: center;
        }

        @media (max-width: 910px) {
          flex-direction: column;
        }

        .tel {
          margin: 0 0 0 20px;
          padding: 5px 0px 5px 50px;
          border-left: 1px solid #e7ddbb;
          display: flex;

          @media (max-width: 1440px) {
            padding: 5px 0px 5px 40px;
          }

          @media (max-width: 1215px) {
            margin: 0 0 0 10px;
            padding: 5px 0px 5px 20px;
          }

          @media (max-width: 910px) {
            border-left: none;
            border-top: 1px solid #e7ddbb;
            padding: 15px 20px 10px 0;
            margin-top: 10px;
          }

          svg,
          img {
            height: 32px;
            width: auto;
            margin: 0 5px;

            @media (max-width: 1440px) {
              height: 26px;
            }
          }

          a:first-child {
            margin-right: 25px;
          }

          a {
            padding: 0;
            font-size: 22px;
            line-height: 28px;
            color: #e7ddbb;
            font-weight: 300;
            flex-direction: row;
            align-items: flex-end;
            transition: all ease 0.5s;

            @media (max-width: 1440px) {
              font-size: 20px;
              line-height: 26px;
            }

            :after {
              display: none;
            }

            span {
              font-size: 18px;
              line-height: 24px;
              margin-right: 5px;

              @media (max-width: 1440px) {
                font-size: 16px;
                line-height: 22px;
              }
            }

            :hover {
              opacity: 0.4;
            }
          }
        }

        li {
          margin: 0 20px;

          @media (max-width: 1215px) {
            margin: 0 10px;
          }

          @media (max-width: 910px) {
            margin: 10px 0;
          }

          a {
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            color: #c3bdac;
            padding: 0 10px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (max-width: 1440px) {
              font-size: 16px;
              padding: 0 5px;
              line-height: 22px;
            }

            :after {
              width: 0%;
              height: 4px;
              background-color: #e7ddbb;
              content: '';
              position: absolute;
              bottom: -10px;
              transition: all ease 0.5s;
            }

            :hover {
              :after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
