import { createGlobalStyle } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'antd/es/notification/style/css';
import 'antd/es/carousel/style/css';
import '@fancyapps/fancybox/src/css/core.css';
import '../../node_modules/hamburgers/dist/hamburgers.css';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    overflow-x: hidden;
  }

  body {
    background: #000;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
    font-family: 'Open Sans', sans-serif;
  }

  .disabled {
    display: none;
  }

  .dontshow {
    width: 0px;
    height: 0px;
  }

  input, textarea {
    border-radius: 0;
  }
  

`;
