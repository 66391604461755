// Hooks
import React, { useState } from 'react';
import { FaMapMarkerAlt, FaWhatsapp } from 'react-icons/fa';

// Styles
import { Navbar, FixedNavbar } from './styles';

export default function Header() {
  // Estado
  const [openMenu1, setOpenMenu1] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);

  return (
    <>
      <Navbar isOpen={openMenu1}>
        <div>
          <a href="/" className="Logo">
            <img
              alt="Logo MM Milléo"
              src={require('../../assets/LogoMMM.png')}
            />
          </a>
          <button
            className={`hamburger hamburger--3dxy ${openMenu1 ? 'is-active' : ''
              }`}
            type="button"
            onClick={() => setOpenMenu1(!openMenu1)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <div id="content">
            <div className="header-top">
              <a
                className="endereco"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/M.+M.+Mill%C3%A9o+Incorpora%C3%A7%C3%B5es/@-25.0975614,-50.1585905,15z/data=!4m5!3m4!1s0x0:0x3e83126ea59a351d!8m2!3d-25.0975614!4d-50.1585905"
              >
                <FaMapMarkerAlt />
                Praça Mal. Floriano Peixoto‎, 42 sala 1, Centro, Ponta Grossa -
                PR
              </a>
              <div>
                <a
                  href="https://www.instagram.com/m.m.milleo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('../../assets/instagram.svg')} alt="" />
                </a>
                <a
                  href="https://www.facebook.com/mmmilleo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('../../assets/facebook.svg')} alt="" />
                </a>
              </div>
              <a
                className="email"
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contato@mmmilleo.com.br"
              >
                contato@mmmilleo.com.br
              </a>
            </div>
            <div className="header-bottom">
              <nav>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/#sobrenos" onClick={() => setOpenMenu1(false)}>
                      Sobre Nós
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#empreendimentos"
                      onClick={() => setOpenMenu1(false)}
                    >
                      Empreendimentos
                    </a>
                  </li>
                  <li>
                    <a href="/#blog" onClick={() => setOpenMenu1(false)}>
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="#contato" onClick={() => setOpenMenu1(false)}>
                      Contato
                    </a>
                  </li>
                  <li className="tel">
                    <a href="tel:+554230274940">
                      <span>42</span> 3027.4940
                    </a>
                    <a target='_blank' rel='noopener noreferrer' href="https://api.whatsapp.com/send?phone=5541995758561">
                      <FaWhatsapp />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </Navbar>
      <FixedNavbar isOpen={openMenu2}>
        <div>
          <a href="/" className="Logo">
            <img
              alt="Logo MM Milléo"
              src={require('../../assets/LogoMMM.png')}
            />
          </a>
          <button
            className={`hamburger hamburger--3dxy ${openMenu2 ? 'is-active' : ''
              }`}
            type="button"
            onClick={() => setOpenMenu2(!openMenu2)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <nav>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/#sobrenos" onClick={() => setOpenMenu2(false)}>
                  Sobre Nós
                </a>
              </li>
              <li>
                <a href="/#empreendimentos" onClick={() => setOpenMenu2(false)}>
                  Empreendimentos
                </a>
              </li>
              <li>
                <a href="/#blog" onClick={() => setOpenMenu2(false)}>
                  Blog
                </a>
              </li>
              <li>
                <a href="#contato" onClick={() => setOpenMenu2(false)}>
                  Contato
                </a>
              </li>
              <li className="tel">
                <a href="tel:+554230274940">
                  <span>42</span> 3027.4940
                </a>
                <a target='_blank' rel='noopener noreferrer' href="https://api.whatsapp.com/send?phone=5541995758561">
                  <FaWhatsapp />
                </a>
                <a
                  href="https://www.instagram.com/m.m.milleo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('../../assets/instagram.svg')} alt="" />
                </a>
                <a
                  href="https://www.facebook.com/mmmilleo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('../../assets/facebook.svg')} alt="" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </FixedNavbar>
    </>
  );
}
