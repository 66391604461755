/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Container, Banner, Loading, Relacionados } from './styles';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

// api
import api from '../../services/api';

export default function Detalhes({ match }) {
  const slug = match.params.slug;
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(true);
  const [mes, setMes] = useState();
  const [day, setDay] = useState();
  const [year, setYear] = useState();
  const [otherPosts, setOtherPosts] = useState();

  useEffect(() => {
    api.get(`posts?_embed&slug=${slug}`).then((response) => {
      setPost(response.data[0]);
      setDay(response.data[0].date.substring(8, 10));
      setYear(response.data[0].date.substring(0, 4));
      const month = response.data[0].date.substring(5, 7);
      if (month === '01') {
        setMes('Janeiro');
      }

      if (month === '02') {
        setMes('Fevereiro');
      }

      if (month === '03') {
        setMes('Março');
      }

      if (month === '04') {
        setMes('Abril');
      }

      if (month === '05') {
        setMes('Maio');
      }

      if (month === '06') {
        setMes('Junho');
      }

      if (month === '07') {
        setMes('Julho');
      }

      if (month === '08') {
        setMes('Agosto');
      }

      if (month === '09') {
        setMes('Setembro');
      }

      if (month === '10') {
        setMes('Outubro');
      }

      if (month === '11') {
        setMes('Novembro');
      }

      if (month === '12') {
        setMes('Dezembro');
      }

      setLoading(false);
    });
  }, [slug]);

  useEffect(() => {
    api.get('posts?_embed&per_page=4').then((response) => {
      const filtro = response.data.filter((post) => post.slug !== slug);
      setOtherPosts(filtro);
    });
  });

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {!!post && !!post.title.rendered
            ? `${!!post && post.title.rendered} - M.M. Milléo
          Incorporações`
            : 'Blog | M.M. Milléo Incorporações'}
        </title>
        <meta name="description" content="Blog M.M. Milléo Incorporações" />
        <meta
          name="keywords"
          content="marca, campanha, site, impresso, facebook, identidade visual, logo"
        />
        <meta
          property="og:image"
          content={
            !!post &&
            post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail
              .source_url
          }
        />
      </Helmet>
      <Header />
      <Loading className={loading ? '' : 'disabled'}>
        <div id="preloader_1">
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </Loading>
      <Container className={loading ? 'disabled' : ''}>
        <Banner>
          <h1>{!!post && post.title.rendered}</h1>
          <label className="data">
            {day} de {mes} de {year}
          </label>

          {ReactHtmlParser(!!post && post.content.rendered)}
          <div className="links">
            <p>Compartilhar:</p>
            <div className="sharethis-inline-share-buttons"></div>
          </div>
        </Banner>
        <Relacionados>
          <h2>Veja também</h2>
          <div className="cards">
            {!!otherPosts &&
              otherPosts.map((post) => {
                const day = post.date.substring(8, 10);
                const month = post.date.substring(5, 7);
                const year = post.date.substring(0, 4);
                let mes = '';

                if (month === '01') {
                  mes = 'Janeiro';
                }

                if (month === '02') {
                  mes = 'Fevereiro';
                }

                if (month === '03') {
                  mes = 'Março';
                }

                if (month === '04') {
                  mes = 'Abril';
                }

                if (month === '05') {
                  mes = 'Maio';
                }

                if (month === '06') {
                  mes = 'Junho';
                }

                if (month === '07') {
                  mes = 'Julho';
                }

                if (month === '08') {
                  mes = 'Agosto';
                }

                if (month === '09') {
                  mes = 'Setembro';
                }

                if (month === '10') {
                  mes = 'Outubro';
                }

                if (month === '11') {
                  mes = 'Novembro';
                }

                if (month === '12') {
                  mes = 'Dezembro';
                }
                return (
                  <a className="card" href={`/blog/${post.slug}`} key={post.id}>
                    <div className="imagem">
                      <img
                        alt={
                          !!post._embedded &&
                          post._embedded['wp:featuredmedia'][0].alt_text
                        }
                        src={
                          !!post._embedded &&
                          post._embedded['wp:featuredmedia'][0].media_details
                            .sizes.thumbnail.source_url
                        }
                      />
                    </div>
                    <div className="text">
                      <p className="data">
                        {day} de {mes} de {year}
                      </p>
                      <h3>{post.title.rendered}</h3>
                      {ReactHtmlParser(post.excerpt.rendered)}
                    </div>
                    <button type="button">Continuar lendo</button>
                  </a>
                );
              })}
          </div>
        </Relacionados>
        <Footer />
      </Container>
    </HelmetProvider>
  );
}
