import React, { useEffect, useState, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Carousel } from 'antd';
import $ from 'jquery';
import { Helmet } from 'react-helmet';
import Lightbox, { Modal, ModalGateway } from 'react-images';

import {
  Container,
  Banner,
  About,
  Video,
  Plantas,
  Imagens,
  Local,
  Loading,
} from './styles';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

// api
import api from '../../services/api';

export default function Sobre({ match }) {
  const slug = match.params.slug;
  const [empreendimento, setEmpreendimento] = useState();
  const [loading, setLoading] = useState(true);
  const [modalisopen, setModalIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const plantasBanner = useRef();
  let index = -1;
  const plant = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    api.get(`/empreendimentos?slug=${slug}`).then((response) => {
      setEmpreendimento(response.data[0]);
      setLoading(false);
    });
  }, [slug]);

  window.jQuery = $;
  require('@fancyapps/fancybox/dist/jquery.fancybox');

  const images =
    !!empreendimento &&
    !!empreendimento.acf.galeria &&
    empreendimento.acf.galeria.map((foto) => ({
      index: ++index,
      src: foto.sizes.large,
    }));

  console.log(selectedIndex);

  return (
    <>
      <Helmet>
        <title>
          {!!empreendimento
            ? `${
                !!empreendimento && empreendimento.title.rendered
              } - M.M. Milléo
          Incorporações`
            : 'Empreendimento | M.M. Milléo Incorporações'}
        </title>
        <meta
          name="description"
          content={!!empreendimento && empreendimento.acf.descricao}
        />
        <meta
          name="keywords"
          content="marca, campanha, site, impresso, facebook, identidade visual, logo"
        />
        <meta
          property="og:image"
          content={
            !!empreendimento &&
            empreendimento.acf.imagem_principal.sizes.thumbnail
          }
        />
      </Helmet>
      <Header />
      <Loading className={loading ? '' : 'disabled'}>
        <div id="preloader_1">
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </Loading>
      <Container className={loading ? 'disabled' : ''}>
        <Banner>
          <div className="slider">
            <div className="text">
              <div>
                {!!empreendimento && empreendimento.acf.logo && (
                  <div className="imagem-logo">
                    <img
                      alt={!!empreendimento && empreendimento.acf.logo.alt}
                      src={
                        !!empreendimento &&
                        empreendimento.acf.logo.sizes.thumbnail
                      }
                    />
                  </div>
                )}
                {!!empreendimento && empreendimento.acf.slogan && (
                  <h2>
                    {!!empreendimento &&
                      empreendimento.acf.slogan.toUpperCase()}
                  </h2>
                )}
                {!!empreendimento &&
                  empreendimento.acf.caracteristicas === '' && (
                    <p>{!!empreendimento && empreendimento.acf.descricao}</p>
                  )}
                {ReactHtmlParser(
                  !!empreendimento && empreendimento.acf.caracteristicas
                )}
              </div>
            </div>
            <div className="imagem-predio">
              <img
                alt={
                  !!empreendimento && empreendimento.acf.imagem_principal.alt
                }
                src={
                  !!empreendimento &&
                  empreendimento.acf.imagem_principal.sizes.large
                }
              />
            </div>
          </div>
        </Banner>

        <About>
          <div className="bg" />
          {!!empreendimento && empreendimento.acf.o_edifico && (
            <div className="edificio">
              <h2>O Edifício</h2>
              <div className="lista">
                {ReactHtmlParser(
                  !!empreendimento && empreendimento.acf.o_edifico
                )}
              </div>
            </div>
          )}
        </About>
        {!!empreendimento && empreendimento.acf.video && (
          <Video>
            <h2>Vídeo</h2>
            <div className="line" />
            <video controls autoPlay={true}>
              <source
                src={!!empreendimento && empreendimento.acf.video}
                type="video/mp4"
              />
            </video>
          </Video>
        )}
        {!!empreendimento && empreendimento.acf.plantas && (
          <Plantas>
            <div className="bg" />

            <h2>Plantas</h2>
            <div className="line" />
            <div className="carousel">
              <button
                type="button"
                onClick={() => plantasBanner.current.prev()}
              >
                Anterior
              </button>
              <Carousel ref={plantasBanner} {...plant} effect="fade">
                {!!empreendimento &&
                  empreendimento.acf.plantas.map((planta) => (
                    <div className="slider" key={planta.imagem.id}>
                      <a
                        id="image"
                        href={planta.imagem.url}
                        data-fancybox="gallery"
                      >
                        <img
                          alt={planta.imagem.alt}
                          src={planta.imagem.sizes.large}
                        />
                      </a>
                      <p>{planta.descricao}</p>
                    </div>
                  ))}
              </Carousel>
              <button
                type="button"
                onClick={() => plantasBanner.current.next()}
              >
                Próximo
              </button>
            </div>
          </Plantas>
        )}

        {!!empreendimento && empreendimento.acf.galeria && (
          <Imagens>
            <h2>Projeções</h2>
            <div className="line" />
            <div className="imagens">
              {!!empreendimento &&
                empreendimento.acf.galeria.map((imagem, index) => (
                  <div
                    onClick={() => {
                      setModalIsOpen(!modalisopen);
                      setSelectedIndex(index);
                    }}
                    key={imagem.id}
                  >
                    <img alt={imagem.alt} src={imagem.sizes.medium} />
                    <p>{imagem.title}</p>
                  </div>
                ))}
            </div>
            <div className="bg" />
          </Imagens>
        )}
        {!!empreendimento && empreendimento.acf.localizacao && (
          <Local>
            <h2>Localização</h2>
            <div className="line" />
            {ReactHtmlParser(
              !!empreendimento && empreendimento.acf.localizacao
            )}
          </Local>
        )}
        <Footer />
      </Container>

      {/* Gateway lightbox */}
      <ModalGateway>
        {modalisopen && (
          <Modal onClose={() => setModalIsOpen(!modalisopen)}>
            <Lightbox views={images} currentIndex={selectedIndex} />
          </Modal>
        )}
      </ModalGateway>
    </>
  );
}
