import React, { useState } from 'react';
import { Content, Direitos } from './styles';
import Input from 'react-input-mask';
import { useFormik } from 'formik';
import { notification } from 'antd';
import * as Yup from 'yup';

import ademail from '../../services/ademail';

export default function Footer() {
  const [disabled, setDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      telefone: Yup.string().required('Campo telefone é obrigatório'),
      mensagem: Yup.string().required('Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
            <p>Nome: ${values.nome}</p>
            <p>E-mail: ${values.email}</p>
            <p>Telefone: ${values.telefone}</p>
            <p>Mensagem: ${values.mensagem}</p>
          `;

      const mail = {
        // to: 'juliana.mmmilleo@gmail.com',
        to: 'larissa.mmmilleo@gmail.com',
        from: values.email,
        as: values.nome,
        subject: 'Novo contato via site - MM Milléo',
        message: body,
      };

      try {
        // Sucesso ao enviar
        setDisabled(true);
        await ademail.post('email', mail,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });
        setDisabled(false);
        resetForm();
      } catch (error) {
        // Erro ao enviar
        setDisabled(false);
        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  const newsletter = useFormik({
    initialValues: {
      email: '',
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
            <p>E-mail: ${values.email}</p>
          `;

      const mail = {
        // to: 'juliana.mmmilleo@gmail.com',
        to: 'larissa.mmmilleo@gmail.com',
        from: values.email,
        as: values.email,
        subject: 'Novo interessado em newsletter - MM Milléo',
        message: body,
      };

      try {
        // Sucesso ao enviar
        setDisabled(true);
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });
        setDisabled(false);
        resetForm();
      } catch (error) {
        // Erro ao enviar
        setDisabled(false);
        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  return (
    <>
      <Content>
        <div className="banner">
          <div className="text">
            <div className="list">
              <h2>Com a MM Milléo você tem</h2>
              <div>
                <p>Qualidade</p>
                <p>Credibilidade</p>
                <p>Agilidade</p>
              </div>
            </div>
            <div className="line" />
            <div className="newsletter">
              <div className="receba">
                <p>
                  Receba <span>novidades</span> no seu e-mail
                </p>
              </div>
              <form onSubmit={newsletter.handleSubmit}>
                <div>
                  <input
                    type="email"
                    placeholder="Digite seu e-mail"
                    name="email"
                    onChange={newsletter.handleChange}
                    onBlur={newsletter.handleBlur}
                    value={newsletter.values.email}
                  />
                  {newsletter.touched.email && newsletter.errors.email ? (
                    <span>{newsletter.errors.email}</span>
                  ) : null}
                </div>
                <div className="btn">
                  <button type="submit">Enviar e-mail</button>
                  <div className={disabled ? 'disable' : ''} />
                </div>
              </form>
            </div>
          </div>
          <div className="imagem">
            <img src={require('../../assets/mulherfooter.png')} alt="" />
          </div>
        </div>
        <div className="contato" id="contato">
          <div className="bg" />
          <div className="line" />
          <div className="content">
            <div className="infos">
              <h2>Entre em contato</h2>
              <div className="line2" />
              <div className="informacoes">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/M.+M.+Mill%C3%A9o+Incorpora%C3%A7%C3%B5es/@-25.0975614,-50.1585905,15z/data=!4m5!3m4!1s0x0:0x3e83126ea59a351d!8m2!3d-25.0975614!4d-50.1585905"
                >
                  <img src={require('../../assets/placeholder.svg')} alt="" />
                  <p>
                    Praça Mal. Floriano Peixoto‎, 42 sala 1, Centro, Ponta
                    Grossa-PR
                  </p>
                </a>
                <a href="mailto: contato@mmmilleo.com.br">
                  <img src={require('../../assets/email.svg')} alt="" />
                  <p>contato@mmmilleo.com.br</p>
                </a>
                <a href="tel: +554230274940">
                  <img src={require('../../assets/tele.svg')} alt="" />
                  <p>42 3027.4940</p>
                </a>
                <div className="social">
                  <a
                    href="https://www.instagram.com/m.m.milleo/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={require('../../assets/instagram.svg')} alt="" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://m.facebook.com/mmmilleo"
                    className="fb"
                  >
                    <img src={require('../../assets/facebook.svg')} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="formulario">
              <form onSubmit={formik.handleSubmit}>
                <input
                  type="text"
                  name="nome"
                  placeholder="Nome"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nome}
                />
                {formik.touched.nome && formik.errors.nome ? (
                  <span>{formik.errors.nome}</span>
                ) : null}

                <input
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <span>{formik.errors.email}</span>
                ) : null}

                <Input
                  type="tel"
                  name="telefone"
                  placeholder="Telefone"
                  mask={
                    formik.values.telefone.length < 15
                      ? '(99) 9999-9999?'
                      : '(99) 99999-9999'
                  }
                  formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                  maskChar={null}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.telefone}
                />
                {formik.touched.telefone && formik.errors.telefone ? (
                  <span>{formik.errors.telefone}</span>
                ) : null}

                <textarea
                  name="mensagem"
                  placeholder="Mensagem"
                  cols="30"
                  rows="10"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mensagem}
                />
                {formik.touched.mensagem && formik.errors.mensagem ? (
                  <span>{formik.errors.mensagem}</span>
                ) : null}
                <div className="btn">
                  <button type="submit ">
                    <p>Enviar</p>
                    <div className="line3" />
                  </button>
                  <div className={disabled ? 'disable' : ''} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
      <Direitos>
        <a
          href="https://agenciaade.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Desenvolvido com{' '}
          <img src={require('../../assets/heart.svg')} alt="Coração" /> por
          agenciaade.com.br
        </a>
      </Direitos>
    </>
  );
}
