import styled from 'styled-components';

import bg_footer from '../../assets/piso.jpg';

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Loading = styled.div`
  width: 100vw;
  height: calc(100vh - 140px);
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1270px) {
    height: calc(100vh - 130px);
  }

  @media (max-width: 1000px) {
    height: calc(100vh - 110px);
  }

  @media (max-width: 550px) {
    height: calc(100vh - 98px);
  }

  @media (max-width: 500px) {
    height: calc(100vh - 80px);
  }

  #preloader_1 {
    position: absolute;
    width: 65px;
    height: 50px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 9px;
    height: 5px;
    background: #fff;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 11px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 22px;
    animation-delay: 0.4s;
  }
  #preloader_1 span:nth-child(4) {
    left: 33px;
    animation-delay: 0.6s;
  }
  #preloader_1 span:nth-child(5) {
    left: 44px;
    animation-delay: 0.8s;
  }
  @keyframes preloader_1 {
    0% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
    25% {
      height: 30px;
      transform: translateY(15px);
      background: #fff;
    }
    50% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
    100% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
  }
`;

export const Banner = styled.section`
  position: relative;
  height: fit-content;
  width: 100%;
  z-index: 2;

  .detail {
    position: absolute;
    right: -40px;
    width: 55%;
    height: 240px;
    bottom: -150px;
    z-index: -1;
  }

  .slider {
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;

    @media (max-width: 750px) {
      flex-direction: column-reverse;
    }

    .imagem-predio {
      width: 61.5%;
      height: 1300px;

      @media (max-width: 1440px) {
        height: 1000px;
      }

      @media (max-width: 900px) {
        height: 800px;
      }

      @media (max-width: 750px) {
        width: 100%;
        height: 100vh;
        margin-bottom: 70px;
      }

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }

    .text {
      width: 38.5%;
      padding: 0px 20px;
      margin-bottom: 165px;

      @media (max-width: 1440px) {
        margin-bottom: 100px;
      }

      @media (max-width: 750px) {
        width: 100%;
        margin-bottom: 70px;
      }

      > div {
        width: 55%;
        display: flex;
        flex-direction: column;
        margin: auto;

        @media (max-width: 1440px) {
          width: 75%;
        }

        @media (max-width: 1000px) {
          width: 100%;
        }
      }

      .imagem-logo {
        width: 45%;
        height: auto;
        margin-bottom: 40px;

        @media (max-width: 750px) {
          width: 30%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: auto;
        }
      }

      h2 {
        font-size: 28px;
        line-height: 38px;
        color: #e7ddbb;
        font-weight: 300;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        font-weight: 300;
        margin-top: 25px;
      }
    }
  }
`;

export const About = styled.div`
  width: 100%;
  padding: 85px 20px 55px 20px;
  display: flex;
  align-items: center;
  position: relative;
  top: -165px;

  @media (max-width: 1440px) {
    top: -100px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 750px) {
    padding: 55px 20px 35px 20px;
    top: 0px;
    margin-bottom: 80px;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    background-image: url(${bg_footer});
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.67);
    background-blend-mode: overlay;
    z-index: -1;

    @media (max-width: 1050px) {
      width: 95%;
    }
  }

  .edificio {
    display: flex;
    flex-direction: column;
    width: 75%;
    justify-content: flex-end;
    max-width: 920px;
    margin-left: 14.5%;

    @media (max-width: 1250px) {
      margin-left: 5%;
    }

    @media (max-width: 1050px) {
      width: 95%;
      max-width: 100%;
    }

    @media (max-width: 900px) {
      width: 95%;
    }

    @media (max-width: 750px) {
      margin-left: 0;
    }

    h2 {
      font-size: 60px;
      line-height: 72px;
      color: #e7ddbb;
      font-weight: 300;
      margin-bottom: 100px;

      @media (max-width: 1440px) {
        font-size: 50px;
        line-height: 55px;
      }

      @media (max-width: 750px) {
        font-size: 40px;
        margin-bottom: 60px;
        line-height: 52px;
      }
    }

    .lista {
      display: grid;
      grid-column-gap: 50px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(2, 400px);
      width: 100%;

      @media (max-width: 1555px) {
        grid-template-columns: repeat(2, 48%);
      }

      @media (max-width: 1305px) {
        width: 90%;
      }

      @media (max-width: 1050px) {
        width: 100%;
        grid-template-columns: repeat(2, 46%);
      }

      @media (max-width: 600px) {
        grid-template-columns: 100%;
      }

      ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 400px);
        grid-column-gap: 50px;
        grid-row-gap: 20px;

        @media (max-width: 1555px) {
          grid-template-columns: repeat(2, 48%);
        }

        @media (max-width: 1050px) {
          grid-template-columns: repeat(2, 46%);
        }

        @media (max-width: 600px) {
          grid-template-columns: 100%;
        }
      }

      p,
      li {
        font-size: 20px;
        line-height: 19px;
        color: #fff;
        margin: 0;
        font-weight: 300;

        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
`;

export const Plantas = styled.section`
  width: 100%;
  position: relative;
  padding: 125px 0px 85px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  @media (max-width: 750px) {
    padding: 50px 0px;
  }

  h2 {
    font-size: 60px;
    line-height: 72px;
    color: #22201b;
    font-weight: 300;
    width: 75%;
    text-align: start;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 55px;
    }

    @media (max-width: 750px) {
      font-size: 40px;
      line-height: 52px;
    }
  }

  .line {
    position: relative;
    left: -20px;
    width: 26%;
    height: 4px;
    background-color: #cdb98d;
    align-self: flex-start;

    @media (max-width: 750px) {
      width: 50%;
    }

    @media (max-width: 600px) {
      margin-bottom: 100px;
    }
  }

  .carousel {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    padding-bottom: 25px;
    border: none;
    border-bottom: 4px solid #22201b;
    font-size: 20px;
    line-height: 27px;
    color: #22201b;
    cursor: pointer;
    transition: all ease 0.5s;

    @media (max-width: 1440px) {
      font-size: 16px;
      padding-bottom: 20px;
      line-height: 23px;
    }

    @media (max-width: 750px) {
      width: fit-content;
      padding: 25px 20px;
    }

    @media (max-width: 600px) {
      position: absolute;
      z-index: 1;
      top: -60px;
      padding: 10px 20px;
      width: 45%;
      left: 0;
    }

    :hover {
      border-bottom: 4px solid #cdb98d;
    }
  }

  button:last-child {
    justify-content: flex-start;

    @media (max-width: 600px) {
      right: 0;
      left: unset;
    }
  }

  .ant-carousel {
    width: 63%;
    margin: auto 20px;

    @media (max-width: 1440px) {
      width: 50%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .slick-slider,
  .slick-list,
  .slick-slide {
    width: 100%;
    height: fit-content;
    position: relative;
    margin: auto;

    @media (max-width: 750px) {
      height: fit-content;
    }
  }

  .slick-slide {
    > div {
      height: fit-content;
      display: flex;
      align-items: center;

      @media (max-width: 750px) {
        height: fit-content;
      }
    }
  }

  .slider {
    width: 100%;
    height: fit-content;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    #image {
      width: 100%;
      height: 100%;

      img {
        object-fit: contain;
        height: 625px;
        width: 100%;

        @media (max-width: 750px) {
          height: 400px;
        }

        @media (max-width: 600px) {
          height: 350px;
        }

        @media (max-width: 500px) {
          height: 300px;
        }

        @media (max-width: 400px) {
          height: 250px;
        }
      }
    }

    p {
      font-size: 40px;
      line-height: 55px;
      color: #a28a46;
      font-weight: 300;
      margin-top: 50px;

      @media (max-width: 1440px) {
        font-size: 20px;
        line-height: 28px;
        margin-top: 0px;
      }
    }
  }
`;

export const Imagens = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 150px 20px;
  position: relative;

  @media (max-width: 750px) {
    padding: 80px 20px;
  }

  .bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-image: url(${bg_footer});
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.67);
    background-blend-mode: overlay;
    z-index: -1;
  }

  h2 {
    font-size: 60px;
    line-height: 72px;
    color: #e7ddbb;
    font-weight: 300;
    width: 75%;
    text-align: start;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 55px;
    }

    @media (max-width: 750px) {
      font-size: 40px;
      line-height: 52px;
    }
  }

  .line {
    position: relative;
    left: -20px;
    width: 30%;
    height: 4px;
    background-color: #cdb98d;
    align-self: flex-start;
    margin-bottom: 70px;

    @media (max-width: 750px) {
      width: 70%;
    }

    @media (max-width: 600px) {
      margin-bottom: 40px;
    }
  }

  .imagens {
    display: grid;
    list-style: none;
    grid-template-columns: repeat(3, 470px);
    grid-auto-rows: 350px;
    grid-gap: 100px;
    margin-top: 70px;
    width: 100%;
    justify-content: center;

    @media (max-width: 1650px) {
      grid-gap: 55px;
      grid-template-columns: repeat(3, 400px);
    }

    @media (max-width: 1440px) {
      grid-gap: 55px;
      grid-template-columns: repeat(3, 350px);
      grid-auto-rows: 280px;
      margin-top: 40px;
    }

    @media (max-width: 1210px) {
      grid-template-columns: repeat(2, 350px);
    }

    @media (max-width: 790px) {
      grid-template-columns: repeat(2, 300px);
      grid-auto-rows: 250px;
    }

    @media (max-width: 710px) {
      margin-top: 0px;
      grid-gap: 30px;
    }

    @media (max-width: 670px) {
      grid-template-columns: 100%;
    }

    div {
      width: 100%;
      height: 100%;
      transition: all ease 0.5s;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;

      :hover {
        transform: scale(1.05);
      }

      p {
        position: absolute;
        bottom: 30px;
        width: 325px;
        text-align: center;
        background-color: #e7ddbb;
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        color: #1f1d17;
        margin: 0;
        padding: 15px 0;

        @media (max-width: 900px) {
          width: 250px;
        }

        @media (max-width: 1440px) {
          font-size: 20px;
          line-height: 26px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;

export const Local = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 150px;

  @media (max-width: 750px) {
    margin-top: 80px;
  }

  h2 {
    font-size: 60px;
    line-height: 72px;
    color: #e7ddbb;
    font-weight: 300;
    width: 75%;
    text-align: start;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 55px;
    }

    @media (max-width: 750px) {
      font-size: 40px;
      line-height: 52px;
    }
  }

  .line {
    position: relative;
    left: -20px;
    width: 32%;
    height: 4px;
    background-color: #cdb98d;
    align-self: flex-start;
    margin-bottom: 70px;

    @media (max-width: 750px) {
      width: 70%;
    }

    @media (max-width: 600px) {
      margin-bottom: 40px;
    }
  }
  iframe {
    width: 100%;
    height: 500px;

    @media (max-width: 750px) {
      height: 350px;
    }
  }
`;

export const Video = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 100px;
  padding: 0 20px;

  @media (max-width: 600px) {
    margin-bottom: 70px;
  }

  h2 {
    font-size: 60px;
    line-height: 72px;
    color: #e7ddbb;
    font-weight: 300;
    width: 75%;
    text-align: start;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 55px;
    }

    @media (max-width: 750px) {
      font-size: 40px;
      line-height: 52px;
    }
  }

  .line {
    position: relative;
    left: -20px;
    width: 24%;
    height: 4px;
    background-color: #cdb98d;
    align-self: flex-start;
    margin-bottom: 70px;

    @media (max-width: 750px) {
      width: 50%;
    }

    @media (max-width: 600px) {
      margin-bottom: 40px;
    }
  }

  video {
    width: 70%;
    height: auto;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;
