import styled, { keyframes } from 'styled-components';

import bg from '../../assets/piso.jpg';

const heartBeat = keyframes`
    0%, 100%, 40%, 60% {
        transform: scale(1);
    }
    20%, 80% {
        transform: scale(1.3);
    }
`;

export const Content = styled.footer`
  width: 100%;
  margin-top: 280px;
  position: relative;
  overflow: hidden;

  @media (max-width: 750px) {
    margin-top: 120px;
  }

  .banner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;

    .text {
      width: 55%;
      margin: 230px 0 0px 0;
      position: relative;
      left: 65px;
      background-color: #fff;
      z-index: 1;
      padding: 60px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 1440px) {
        margin: 100px 0 25px 0;
        left: 20px;
        padding: 30px 20px;
      }

      @media (max-width: 400px) {
        left: 10px;
        width: 75%;
      }

      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 685px;
        margin-bottom: 30px;

        @media (max-width: 1320px) {
          width: 100%;
        }

        @media (max-width: 920px) {
          align-items: flex-start;
          flex-direction: column;
          padding-left: 30px;
        }

        @media (max-width: 750px) {
          margin-bottom: 20px;
          padding-left: 10px;
        }

        h2 {
          font-size: 40px;
          line-height: 50px;
          color: #a28a46;
          max-width: 190px;
          font-weight: 300;

          @media (max-width: 1440px) {
            font-size: 35px;
            line-height: 35px;
            max-width: 170px;
          }
        }

        p {
          font-size: 31px;
          line-height: 60px;
          font-weight: 300;
          color: #a28a46;
          margin: 10px 0;
          width: fit-content;
          display: flex;
          align-items: center;

          @media (max-width: 1440px) {
            font-size: 20px;
            margin: 5px 0;
            line-height: 38px;
          }

          :before {
            position: relative;
            width: 68px;
            height: 3px;
            background-color: #e7ddbb;
            content: '';
            margin-right: 40px;

            @media (max-width: 750px) {
              width: 25px;
              margin-right: 25px;
            }
          }
        }
      }

      .line {
        width: 137%;
        height: 4px;
        position: relative;
        left: 35%;
        background-color: #cdb98d;

        @media (max-width: 1840px) {
          width: 150%;
          left: 30%;
        }

        @media (max-width: 650px) {
          left: 35%;
          width: 175%;
        }

        @media (max-width: 400px) {
          width: 140%;
          left: 19%;
        }
      }

      .newsletter {
        position: relative;
        z-index: 2;
        width: 137%;
        padding: 30px 25px 30px 0;
        background-color: #fff;
        left: 35%;
        display: flex;
        margin-top: 35px;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: 1840px) {
          left: 30%;
          width: 150%;
        }

        @media (max-width: 870px) {
          padding: 20px 15px 20px 0;
        }

        @media (max-width: 858px) {
          flex-direction: column;
        }

        @media (max-width: 750px) {
          margin-top: 25px;
        }

        @media (max-width: 650px) {
          width: 175%;
          left: 35%;
        }

        @media (max-width: 430px) {
          width: 191%;
          left: 44%;
        }

        @media (max-width: 400px) {
          width: 140%;
          left: 19%;
        }

        .receba {
          width: 23%;
          background-color: #000;
          padding: 25px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;

          @media (max-width: 1280px) {
            width: 27%;
          }

          @media (max-width: 1100px) {
            width: 35%;
            position: relative;
            margin-right: 20px;
          }

          @media (max-width: 858px) {
            padding: 20px 25px;
            width: 100%;
            margin-right: 0;
          }
        }

        p {
          color: #fff;
          font-size: 22px;
          line-height: 30px;
          margin: 0;
          max-width: 190px;

          @media (max-width: 1671px) {
            max-width: 235px;
          }

          @media (max-width: 1440px) {
            font-size: 18px;
            line-height: 27px;
            max-width: 100%;
          }

          @media (max-width: 1288px) {
            max-width: 200px;
          }

          @media (max-width: 858px) {
            max-width: 100%;
          }

          @media (max-width: 380px) {
            max-width: 200px;
          }

          span {
            color: #cdb98d;
          }
        }

        form {
          display: flex;
          width: 70%;
          height: 90px;

          @media (max-width: 1100px) {
            width: 64%;
          }

          @media (max-width: 858px) {
            width: 100%;
          }

          > div {
            width: 65%;

            @media (max-width: 400px) {
              width: 76%;
            }
          }

          .btn {
            position: relative;
            width: 35%;
            padding: 15px 25px;
            background-color: #000;

            @media (max-width: 858px) {
              width: 40%;
            }

            @media (max-width: 400px) {
              /* width: fit-content; */
              padding: 10px 15px;
            }

            .disable {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
            }
          }

          input {
            height: 100%;
            width: 100%;
            border: none;
            border-bottom: 1px solid #707070;
            font-size: 23px;
            line-height: 31px;
            color: #969693;

            @media (max-width: 1440px) {
              font-size: 16px;
              line-height: 24px;
            }
          }

          button {
            height: 100%;
            background-color: #000;
            border: none;
            color: #e7ddbb;
            font-size: 20px;
            line-height: 27px;
            font-weight: 700;
            padding: 0 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            cursor: pointer;
            position: relative;

            @media (max-width: 1440px) {
              font-size: 16px;
              line-height: 24px;
              padding: 0 40px;
            }

            @media (max-width: 750px) {
              padding: 0 20px;
            }

            :after {
              width: 0%;
              height: 4px;
              background-color: #e7ddbb;
              content: '';
              position: absolute;
              bottom: 0px;
              transition: all ease 0.5s;
            }

            :hover {
              :after {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .imagem {
      width: 50%;
      height: 100%;
      position: absolute;
      z-index: 0;
      right: 0;

      @media (max-width: 920px) {
        width: 70%;
      }

      @media (max-width: 540px) {
        width: 100%;
      }

      @media (max-width: 400px) {
        display: none;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .contato {
    padding: 150px 20px 70px 20px;
    position: relative;

    @media (max-width: 750px) {
      padding: 80px 20px 30px 20px;
    }

    .line {
      position: relative;
      width: 65%;
      height: 4px;
      background-color: #cdb98d;
      margin: auto;
      margin-bottom: 130px;

      @media (max-width: 750px) {
        margin-bottom: 80px;
      }

      @media (max-width: 450px) {
        width: 80%;
      }
    }

    .bg {
      width: 51.5%;
      height: calc(100% + 220px);
      position: absolute;
      background-image: url(${bg});
      background-size: cover;
      top: -220px;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.67);
      background-blend-mode: overlay;
      left: -20px;

      @media (max-width: 750px) {
        width: calc(100% + 20px);
        height: 73%;
      }
    }

    .content {
      display: flex;

      @media (max-width: 750px) {
        flex-direction: column;
        align-items: center;
      }

      /* @media (max-width: 540px) {
        align-items: flex-start;
      } */
    }

    h2 {
      margin-bottom: 40px;
      font-size: 40px;
      line-height: 50px;
      color: #c3bdac;
      font-weight: 300;
      max-width: 280px;

      @media (max-width: 1440px) {
        font-size: 35px;
        line-height: 35px;
        max-width: 273px;
        margin-bottom: 25px;
      }
    }

    a {
      font-size: 18px;
      line-height: 22px;
      font-weight: 300;
      color: #e7ddbb;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: all ease 0.5s;
      margin: 20px 0px;

      @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 24px;
      }

      @media (max-width: 540px) {
        align-items: center;
      }

      :hover {
        color: #cdb98d;
      }

      p {
        margin: 0;
        margin-left: 20px;
      }
    }

    .social {
      display: flex;

      a {
        margin: 20px 10px;

        img {
          height: 30px;
        }
      }
    }

    .infos {
      width: 51.5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 20px;
      position: relative;

      @media (max-width: 750px) {
        width: 100%;
      }

      h2 {
        position: relative;
        right: 140px;

        @media (max-width: 1145px) {
          right: 0;
        }
      }

      .line2 {
        width: 55%;
        height: 4px;
        background-color: #cdb98d;
        position: relative;
        left: -20px;
        align-self: flex-start;
      }

      .informacoes {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        max-width: 375px;
        position: relative;
        right: 80px;

        @media (max-width: 1145px) {
          right: 0;
        }
      }
    }

    .formulario {
      width: 48.5%;
      max-width: 586px;
      margin: auto;

      @media (max-width: 1440px) {
        max-width: 480px;
      }

      @media (max-width: 750px) {
        padding: 60px 0px 0 0px;
        width: 100%;
      }

      form {
        display: flex;
        flex-direction: column;
      }

      span {
        color: red;
        font-size: 14px;
        margin-top: 5px;
      }

      input,
      textarea {
        font-size: 20px;
        line-height: 28px;
        color: #c3bdac;
        border: 1px solid #766e57;
        margin: 15px 0;
        background-color: #000;
        width: 100%;

        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 24px;
          margin: 10px 0;
        }
      }

      input {
        height: 80px;
        padding: 0 35px;

        @media (max-width: 1440px) {
          padding: 0 15px;
          height: 50px;
        }
      }

      textarea {
        height: 215px;
        resize: none;
        padding: 30px 35px;

        @media (max-width: 1440px) {
          padding: 15px;
          height: 150px;
        }
      }

      .btn {
        position: relative;

        .disable {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
      }

      button {
        width: 100%;
        background-color: transparent;
        cursor: pointer;
        margin-top: 20px;
        border: none;
        position: relative;
        display: flex;
        justify-content: flex-end;

        p {
          font-size: 28px;
          line-height: 38px;
          color: #a28a46;
          background-color: #ffffff;
          width: fit-content;
          padding: 25px 60px;
          transition: all ease 0.5s;
          margin: 0;

          @media (max-width: 1440px) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .line3 {
          position: absolute;
          width: calc(100% - 30px);
          height: 4px;
          background-color: #cdb98d;
          bottom: 15px;
          left: 0;
        }

        :hover {
          p {
            background-color: #000;
          }
        }
      }
    }
  }
`;

export const Direitos = styled.div`
  padding: 40px 20px 60px 20px;
  display: flex;
  justify-content: center;

  a {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    transition: all ease 0.5s;

    :hover {
      color: #cdb98d;
    }

    img {
      margin: 0 3px;
      height: 15px;

      animation-name: ${heartBeat};
      animation-iteration-count: infinite;
      animation-duration: 2s;
    }
  }
`;
