import styled from 'styled-components';
import piso from '../../assets/piso.jpg';

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Loading = styled.div`
  width: 100vw;
  height: calc(100vh - 140px);
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1270px) {
    height: calc(100vh - 130px);
  }

  @media (max-width: 1000px) {
    height: calc(100vh - 110px);
  }

  @media (max-width: 550px) {
    height: calc(100vh - 98px);
  }

  @media (max-width: 500px) {
    height: calc(100vh - 80px);
  }

  #preloader_1 {
    position: absolute;
    width: 65px;
    height: 50px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 9px;
    height: 5px;
    background: #fff;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 11px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 22px;
    animation-delay: 0.4s;
  }
  #preloader_1 span:nth-child(4) {
    left: 33px;
    animation-delay: 0.6s;
  }
  #preloader_1 span:nth-child(5) {
    left: 44px;
    animation-delay: 0.8s;
  }
  @keyframes preloader_1 {
    0% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
    25% {
      height: 30px;
      transform: translateY(15px);
      background: #fff;
    }
    50% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
    100% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
  }
`;

export const Banner = styled.section`
  width: 100%;
  height: 550px;
  position: relative;

  @media (max-width: 750px) {
    height: 400px;
  }

  .bg {
    width: 45%;
    height: 100%;
    position: absolute;
    background-image: url(${piso});
    background-color: rgba(0, 0, 0, 0.67);
    background-blend-mode: overlay;
    background-size: cover;
    opacity: 0.73;

    @media (max-width: 1000px) {
      width: 60%;
    }

    @media (max-width: 550px) {
      width: 100%;
    }
  }

  .bg-img {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;

    img {
      object-fit: cover;
      object-position: top;
      width: 100%;
      height: 100%;
    }
  }

  .box {
    position: relative;
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px 75px 20px;

    @media (max-width: 1000px) {
      width: 60%;
    }

    @media (max-width: 750px) {
      padding: 0 20px 40px 20px;
    }

    @media (max-width: 550px) {
      width: 100%;
    }

    > div {
      max-width: 550px;
    }

    h2 {
      font-size: 40px;
      line-height: 42px;
      color: #e7ddbb;
      font-weight: 500;
      margin-bottom: 40px;
      width: 100%;

      @media (max-width: 1440px) {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 25px;
      }
    }

    a {
      padding: 20px 30px;
      text-decoration: none;
      color: #e7ddbb;
      font-size: 20px;
      line-height: 27px;
      transition: all ease 0.5s;
      display: flex;
      border-bottom: 4px solid #e7ddbb;
      width: 250%;

      @media (max-width: 1440px) {
        line-height: 23px;
        font-size: 16px;
      }

      :hover {
        background-color: #e7ddbb;
        color: #000;
      }
    }
  }
`;

export const Noticias = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 90px;

  h2 {
    font-size: 40px;
    color: #c3bdac;
    margin: 0;
    line-height: 52px;
    font-weight: 300;
    width: 100%;
    max-width: 1410px;

    @media (max-width: 1440px) {
      font-size: 35px;
      line-height: 40px;
      padding-left: 20px;
      max-width: 1100px;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 400px);
    grid-gap: 85px;
    margin-top: 100px;
    position: relative;
    width: 100%;
    justify-content: center;
    padding: 40px 0;

    @media (max-width: 1440px) {
      grid-gap: 55px;
      grid-template-columns: repeat(3, 330px);
      margin-top: 40px;
    }

    @media (max-width: 1140px) {
      grid-template-columns: repeat(2, 330px);
    }

    @media (max-width: 755px) {
      grid-template-columns: repeat(1, 330px);
    }

    @media (max-width: 370px) {
      grid-template-columns: 100%;
    }

    .bg {
      position: absolute;
      width: 60%;
      height: 100%;
      right: 0;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.67);
      background-image: url(${piso});
      background-size: cover;
      background-repeat: no-repeat;
      background-blend-mode: overlay;
    }

    .card {
      width: 100%;
      height: auto;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      :hover {
        button {
          background-color: #e7ddbb;
          color: #000;
        }
      }

      .imagem {
        width: 100%;
        height: 300px;

        @media (max-width: 1440px) {
          height: 250px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .text {
        padding: 30px 30px 40px 30px;

        @media (max-width: 1440px) {
          padding: 30px 10px 40px 10px;
        }

        .data {
          font-size: 14px;
          line-height: 19px;
          color: #e7ddbb;
          margin-bottom: 20px;
        }

        h3 {
          font-size: 26px;
          font-weight: bold;
          line-height: 26px;
          color: #e7ddbb;
          margin-bottom: 15px;

          @media (max-width: 1440px) {
            font-size: 24px;
            line-height: 30px;
          }
        }

        p {
          font-size: 18px;
          line-height: 24px;
          color: #c3bdac;
          margin: 0;

          @media (max-width: 1440px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      button {
        color: #c3bdac;
        background-color: transparent;
        border: none;
        border-bottom: 4px solid #e7ddbb;
        width: 100%;
        font-size: 20px;
        line-height: 27px;
        transition: all ease 0.5s;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px 30px;

        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }

  .dontdisplay {
    z-index: -1;
    opacity: 0.5;
  }

  .mais {
    font-size: 20px;
    line-height: 27px;
    border: none;
    cursor: pointer;
    border-bottom: 4px solid #e7ddbb;
    padding: 20px 50px;
    transition: all ease 0.5s;
    color: #c3bdac;
    margin-top: 150px;
    background-color: transparent;
    display: flex;
    width: fit-content;

    @media (max-width: 1440px) {
      font-size: 18px;
      line-height: 25px;
      margin-top: 100px;
    }

    :hover {
      background-color: #e7ddbb;
      color: #000;
    }
  }
`;
