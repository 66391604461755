import styled from 'styled-components';
import piso from '../../assets/piso.jpg';

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Loading = styled.div`
  width: 100vw;
  height: calc(100vh - 140px);
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1270px) {
    height: calc(100vh - 130px);
  }

  @media (max-width: 1000px) {
    height: calc(100vh - 110px);
  }

  @media (max-width: 550px) {
    height: calc(100vh - 98px);
  }

  @media (max-width: 500px) {
    height: calc(100vh - 80px);
  }

  #preloader_1 {
    position: absolute;
    width: 65px;
    height: 50px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 9px;
    height: 5px;
    background: #fff;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 11px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 22px;
    animation-delay: 0.4s;
  }
  #preloader_1 span:nth-child(4) {
    left: 33px;
    animation-delay: 0.6s;
  }
  #preloader_1 span:nth-child(5) {
    left: 44px;
    animation-delay: 0.8s;
  }
  @keyframes preloader_1 {
    0% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
    25% {
      height: 30px;
      transform: translateY(15px);
      background: #fff;
    }
    50% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
    100% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
  }
`;

export const Banner = styled.section`
  position: relative;
  background: black;
  z-index: 9;
  height: calc(100vh - 140px);
  width: 100%;
  min-height: 650px;
  margin-bottom: 145px;

  @media (max-width: 1440px) {
    height: calc(100vh - 119px);
  }

  @media (max-width: 1270px) {
    height: calc(100vh - 110px);
  }

  @media (max-width: 1000px) {
    height: calc(100vh - 95px);
  }

  @media (max-width: 550px) {
    height: calc(100vh - 85px);
  }

  @media (max-width: 500px) {
    height: calc(100vh - 80px);
  }

  @media (max-width: 750px) {
    margin-bottom: 100px;
  }

  #btns {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 28%;
    z-index: 9;
    display: flex;

    @media (max-width: 1440px) {
      width: 32%;
    }

    @media (max-width: 745px) {
      background-color: #000;
      width: 50%;
    }

    @media (max-width: 450px) {
      width: 100%;
    }

    button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      width: 50%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease 0.5s;

      @media (max-width: 450px) {
        height: 60px;
      }

      svg {
        height: 40px;
        width: auto;
        fill: #766d4f;

        @media (max-width: 450px) {
          height: 30px;
        }
      }

      :hover {
        background-color: rgba(231, 221, 187, 0.11);
      }
    }
  }

  .ant-carousel,
  .slick-slider,
  .slick-list,
  .slick-slide {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .slick-slide {
    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .slick-track {
    height: 100%;
  }

  .slider {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex !important;

    .img {
      width: 72%;
      height: 100%;
      position: relative;
      z-index: -1;

      @media (max-width: 1440px) {
        width: 68%;
      }

      @media (max-width: 745px) {
        width: 100%;
        position: absolute;
      }

      img {
        object-fit: cover;
        object-position: center bottom;
        width: 100%;
        height: 100%;
      }
    }

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      background-image: url(${piso});
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.5;
      z-index: 0;
      top: 0;

      @media (max-width: 745px) {
        opacity: 0.7;
      }
    }

    .infos {
      position: relative;
      z-index: 1;
      width: 28%;
      height: calc(100% - 80px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px 0 7%;

      @media (max-width: 1440px) {
        width: 32%;
      }

      @media (max-width: 1000px) {
        padding: 0 20px;
      }

      @media (max-width: 745px) {
        width: 100%;
      }

      .imagem-logo {
        width: 100%;
        height: 23vh;
        margin-bottom: 65px;

        @media (max-width: 745px) {
          width: 150px;
          margin-bottom: 40px;
        }

        img {
          object-fit: contain;
          object-position: center;
          width: auto;
          height: 100%;
        }
      }

      h2 {
        font-size: 28px;
        line-height: 38px;
        color: #e7ddbb;
        margin-bottom: 25px;

        @media (max-width: 1440px) {
          font-size: 24px;
          line-height: 34px;
        }

        @media (max-width: 745px) {
          margin-bottom: 15px;
          max-width: 305px;
        }
      }

      p {
        font-size: 16px;
        line-height: 22px;
        color: #fff;
        margin-bottom: 50px;

        @media (max-width: 1440px) {
          max-width: 300px;
          margin-bottom: 35px;
        }
      }

      a {
        width: 345%;
        height: fit-content;
        padding: 15px 35px;
        font-size: 20px;
        line-height: 27px;
        color: #e7ddbb;
        display: flex;
        border-bottom: 4px solid #e7ddbb;
        transition: all ease 0.5s;

        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 25px;
          width: 265%;
        }

        @media (max-width: 745px) {
          width: 100%;
        }

        :hover {
          background-color: #e7ddbb;
          color: #000;
        }
      }
    }
  }
`;

export const Unique = styled.section`
  width: 100%;
  position: relative;
  padding: 230px 0 30px 0;
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1440px) {
    padding: 180px 0 30px 0;
  }

  @media (max-width: 750px) {
    margin-bottom: 100px;
  }

  @media (max-width: 338px) {
    padding: 215px 0 30px 0;
  }

  .btns {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1;

    button {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      background-color: transparent;
      padding: 25px 10px;
      border: none;
      border-bottom: 4px solid #e7ddbb;
      font-size: 20px;
      line-height: 27px;
      color: #e7ddbb;
      cursor: pointer;
      transition: all ease 0.5s;

      @media (max-width: 1440px) {
        font-size: 16px;
        padding: 10px 20px;
        line-height: 23px;
      }

      @media (max-width: 750px) {
        background-color: rgba(0, 0, 0, 0.5);
        width: fit-content;
      }

      :hover {
        background-color: #e7ddbb;
        color: #000;
      }
    }

    button:last-child {
      justify-content: flex-start;
    }
  }

  .white-box {
    position: absolute;
    background-color: #fff;
    height: 100%;
    width: 800px;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 35px;

    @media (max-width: 1440px) {
      width: 600px;
    }

    @media (max-width: 750px) {
      width: 75%;
      padding: 35px 20px 0 20px;
    }

    h2 {
      font-size: 60px;
      line-height: 65px;
      font-weight: 300;
      color: #c3bdac;
      margin: 0;

      @media (max-width: 1440px) {
        font-size: 40px;
        line-height: 45px;
      }
    }

    p {
      font-size: 18px;
      width: fit-content;
      margin: 0;
      line-height: 20px;
      margin-top: 15px;
      color: #000;
      text-align: center;

      @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    .projecoes {
      font-weight: bold;
      color: #a28a46;
    }
  }

  .ant-carousel {
    width: 100%;
    height: 715px;

    @media (max-width: 1440px) {
      height: 515px;
    }

    @media (max-width: 500px) {
      height: 365px;
    }
  }

  .slick-center,
  .slick-current,
  .slick-active {
    > div {
      .slider {
        .img {
          height: 670px;
          width: 995px;
          margin: auto;
          top: 0;
          cursor: pointer;
          transition: all ease 0.5s;

          @media (max-width: 1440px) {
            height: 470px;
            width: 750px;
          }

          @media (max-width: 850px) {
            width: 95%;
          }

          @media (max-width: 500px) {
            height: 320px;
          }

          :hover {
            transform: scale(0.9);
          }
        }
      }
    }
  }

  .slider {
    .img {
      height: 550px;
      position: relative;
      top: 60px;

      @media (max-width: 1440px) {
        height: 350px;
      }

      @media (max-width: 500px) {
        height: 200px;
      }
    }

    p {
      width: fit-content;
      margin: 25px auto 0 auto;
      font-size: 18px;
      line-height: 20px;
      font-weight: bold;
      color: #a28a46;

      @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Prontos = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 135px 20px 235px 20px;
  background-color: #fff;
  width: 100%;

  @media (max-width: 750px) {
    padding: 80px 20px 100px 20px;
  }

  h2 {
    font-size: 60px;
    line-height: 65px;
    color: #c3bdac;
    font-weight: 300;
    margin-bottom: 15px;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 55px;
    }

    @media (max-width: 750px) {
      font-size: 40px;
      text-align: center;
      line-height: 45px;
    }
  }

  p {
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    margin: 0;

    @media (max-width: 1440px) {
      font-size: 16px;
      line-height: 18px;
      text-align: center;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 615px);
    margin-top: 70px;

    @media (max-width: 1885px) {
      grid-template-columns: repeat(3, 550px);
    }

    @media (max-width: 1690px) {
      grid-template-columns: repeat(3, 500px);
    }

    @media (max-width: 1460px) {
      grid-template-columns: repeat(3, 480px);
    }

    @media (max-width: 1440px) {
      grid-template-columns: repeat(3, 440px);
    }

    @media (max-width: 1250px) {
      grid-template-columns: repeat(2, 440px);
    }

    @media (max-width: 805px) {
      grid-template-columns: repeat(2, 50%);
      width: 100%;
    }

    @media (max-width: 760px) {
      grid-template-columns: 100%;
      width: 100%;
    }

    @media (max-width: 750px) {
      margin-top: 50px;
    }

    div:last-child {
      border-right: none;

      @media (max-width: 1250px) {
        border-right: 1px solid #e6e1cf;
      }

      @media (max-width: 760px) {
        border-right: none;
      }
    }

    div:nth-child(2) {
      @media (max-width: 1250px) {
        border-right: none;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 65px 20px;
      border-right: 1px solid #e6e1cf;

      @media (max-width: 760px) {
        border-right: none;
      }

      @media (max-width: 750px) {
        padding: 0px;
        margin-bottom: 50px;
      }

      .img {
        width: 400px;
        height: 425px;

        @media (max-width: 1440px) {
          height: 350px;
          width: 325px;
        }

        @media (max-width: 760px) {
          width: 400px;
        }

        @media (max-width: 440px) {
          width: 100%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .informacoes {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        justify-content: space-between;
        height: 100%;
        width: 400px;

        @media (max-width: 1440px) {
          width: 325px;
        }

        @media (max-width: 760px) {
          width: 400px;
        }

        @media (max-width: 440px) {
          width: 100%;
        }

        .text {
          padding: 0 20px;

          h4 {
            font-size: 21px;
            line-height: 28px;
            font-weight: bold;
            color: #3b3728;
            margin-bottom: 15px;
            text-align: center;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            color: #807b70;
            text-align: center;
          }
        }

        .box {
          width: 100%;
        }

        .infos {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #e7ddbb;
          width: 100%;
          margin-top: 30px;
          padding: 10px 20px;

          @media (max-width: 1440px) {
            padding: 10px;
          }

          @media (max-width: 370px) {
            flex-direction: column;
          }

          > div {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;

            @media (max-width: 370px) {
              width: 100%;
            }

            img {
              margin-right: 15px;

              @media (max-width: 410px) {
                margin-right: 10px;
              }
            }

            p {
              font-size: 16px;
              line-height: 20px;
              color: #9b937c;
              margin: 0;
              max-width: 100px;
            }
          }

          div:first-child {
            border-right: 1px solid #cbc3ae;

            @media (max-width: 370px) {
              border-right: none;
              border-bottom: 1px solid #cbc3ae;
            }
          }
        }

        .endereco {
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #e7ddbb;
          margin-top: 25px;
          padding-bottom: 20px;

          p {
            font-size: 16px;
            line-height: 20px;
            color: #9b937c;
            margin: 0;
            max-width: 225px;
            margin-left: 15px;
          }
        }
      }
    }
  }
`;

export const Sobre = styled.section`
  /* margin-top: -140px; */
  padding: 250px 20px 250px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  left: 0;
  margin-bottom: 150px;

  @media (max-width: 1440px) {
    padding: 200px 20px 200px 0px;
    justify-content: flex-end;
  }

  @media (max-width: 1195px) {
    flex-direction: column;
    padding: 85px 20px 60px 0px;
  }

  @media (max-width: 750px) {
    padding: 85px 20px 50px 0px;
  }

  .bg {
    width: 66.5%;
    height: 100%;
    background-image: url(${piso});
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.67);
    background-blend-mode: overlay;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;

    @media (max-width: 1195px) {
      width: 70%;
    }

    @media (max-width: 1030px) {
      width: 80%;
    }

    @media (max-width: 750px) {
      width: 95%;
    }
  }

  .bg-img {
    width: 50%;
    height: 100%;
    position: relative;

    @media (max-width: 1440px) {
      position: absolute;
      left: 0;
      height: 75%;
    }

    @media (max-width: 1195px) {
      width: 60%;
      position: relative;
      left: unset;
      height: 100%;
    }

    @media (max-width: 750px) {
      width: 90%;
      left: -40px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .text {
    width: 32%;
    padding-left: 70px;
    position: relative;
    z-index: 1;

    @media (max-width: 1530px) {
      width: 45%;
    }

    @media (max-width: 1440px) {
      padding-right: 100px;
      width: 50%;
    }

    @media (max-width: 1195px) {
      width: 65%;
      padding-left: 0px;
      align-self: flex-end;
      margin-top: 70px;
      padding-right: 0px;
    }

    @media (max-width: 1030px) {
      width: 73%;
    }

    @media (max-width: 750px) {
      margin-top: 40px;
      width: 90%;
    }

    h2 {
      font-size: 60px;
      line-height: 72px;
      color: #e7ddbb;
      font-weight: 300;
      margin-bottom: 15px;

      @media (max-width: 1440px) {
        font-size: 50px;
        line-height: 55px;
      }

      @media (max-width: 750px) {
        font-size: 35px;
        line-height: 37px;
      }
    }

    .line {
      width: 170%;
      height: 4px;
      background-color: #e7ddbb;
      position: relative;
      right: 0px;
      margin-top: 40px;
    }

    p {
      font-size: 16px;
      line-height: 32px;
      color: #c3bdac;
      margin-top: 35px;
      max-width: 670px;
    }
  }
`;

export const Blog = styled.section`
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  padding-top: 100px;
  position: relative;
  width: 100%;

  h2 {
    font-size: 70px;
    color: #c3bdac;
    margin: 0;
    line-height: 94px;
    font-weight: 300;
    width: 100%;
    max-width: 1410px;

    @media (max-width: 1440px) {
      font-size: 50px;
      max-width: 1100px;
      line-height: 38px;
    }
  }

  .line {
    width: 23%;
    height: 4px;
    background-color: #cdb98d;
    position: absolute;
    left: 0%;
    top: 200px;

    @media (max-width: 1440px) {
      top: 165px;
    }

    @media (max-width: 1140px) {
      width: 120px;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 400px);
    grid-gap: 85px;
    margin-top: 100px;

    @media (max-width: 1440px) {
      grid-gap: 55px;
      grid-template-columns: repeat(3, 330px);
    }

    @media (max-width: 1140px) {
      grid-template-columns: repeat(2, 330px);
    }

    @media (max-width: 755px) {
      grid-template-columns: repeat(1, 330px);
    }

    @media (max-width: 370px) {
      grid-template-columns: 100%;
    }

    .card {
      width: 100%;
      height: auto;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      :hover {
        button {
          background-color: #e7ddbb;
          color: #000;
        }
      }

      .imagem {
        width: 100%;
        height: 300px;

        @media (max-width: 1440px) {
          height: 250px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .text {
        padding: 30px 30px 40px 30px;

        @media (max-width: 1440px) {
          padding: 30px 10px 40px 10px;
        }

        .data {
          font-size: 14px;
          line-height: 19px;
          color: #e7ddbb;
          margin-bottom: 20px;
        }

        h3 {
          font-size: 26px;
          font-weight: bold;
          line-height: 26px;
          color: #e7ddbb;
          margin-bottom: 15px;

          @media (max-width: 1440px) {
            font-size: 24px;
            line-height: 30px;
          }
        }

        p {
          font-size: 18px;
          line-height: 24px;
          color: #c3bdac;
          margin: 0;

          @media (max-width: 1440px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      button {
        color: #c3bdac;
        background-color: transparent;
        border: none;
        border-bottom: 4px solid #e7ddbb;
        width: 100%;
        font-size: 20px;
        line-height: 27px;
        transition: all ease 0.5s;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px 30px;

        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }

  .link {
    font-size: 20px;
    line-height: 27px;
    border-bottom: 4px solid #e7ddbb;
    padding: 20px 50px;
    transition: all ease 0.5s;
    color: #c3bdac;
    margin-top: 150px;

    @media (max-width: 1440px) {
      font-size: 18px;
      line-height: 25px;
      margin-top: 100px;
    }

    :hover {
      background-color: #e7ddbb;
      color: #000;
    }
  }
`;
