import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { Container, Banner, Noticias, Loading } from './styles';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

// api
// bla bla bla
import api from '../../services/api';

export default function Blog() {
  const [posts, setPosts] = useState();
  const [postPrincipal, setPostPrincipal] = useState();
  const [numerodeposts, setNumerodeposts] = useState(6);
  const [loading, setLoading] = useState(true);
  let notworking = false;

  useEffect(() => {
    api.get(`posts?_embed&per_page=${numerodeposts}`).then((response) => {
      setPostPrincipal(response.data[0]);
      const secposts = response.data;
      secposts.shift();
      setPosts(secposts);
      setLoading(false);
    });
  }, [numerodeposts]);

  if (!!posts && posts.length <= numerodeposts) {
    notworking = true;
  }

  return (
    <>
      <Helmet>
        <title>Blog | M.M. Milléo Incorporações</title>
        <meta
          name="description"
          content=" A M.M.Milléo Construções Civis e Incorporações Imobiliárias ltda é responsável pelas atividades que abrangem desde a escolha dos melhores terrenos até o atendimento pós-venda, passando pelo desenvolvimento dos projetos, construção, incorporação e vendas."
        />
        <meta
          name="keywords"
          content="marca, campanha, site, impresso, facebook, identidade visual, logo"
        />
        <meta
          property="og:image"
          content={require('../../assets/LogoMMM.png')}
        />
      </Helmet>
      <Header />
      <Loading className={loading ? '' : 'disabled'}>
        <div id="preloader_1">
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </Loading>
      <Container className={loading ? 'disabled' : ''}>
        <Banner>
          <div className="bg" />
          <div className="bg-img">
            <img
              alt={
                !!postPrincipal && postPrincipal._embedded
                  ? postPrincipal._embedded['wp:featuredmedia'][0].alt_text
                  : ''
              }
              src={
                !!postPrincipal && postPrincipal._embedded
                  ? postPrincipal._embedded['wp:featuredmedia'][0].media_details
                      .sizes.full.source_url
                  : ''
              }
            />
          </div>
          <div className="box">
            <div>
              <h2>
                {!!postPrincipal && postPrincipal.title.rendered.toUpperCase()}
              </h2>
              <a href={`/blog/${!!postPrincipal && postPrincipal.slug}`}>
                Continuar lendo
              </a>
            </div>
          </div>
        </Banner>
        <Noticias>
          <h2>Mais notícias</h2>
          <div className="cards">
            <div className="bg" />
            {!!posts &&
              posts.map((post) => {
                const day = post.date.substring(8, 10);
                const month = post.date.substring(5, 7);
                const year = post.date.substring(0, 4);
                let mes = '';

                if (month === '01') {
                  mes = 'Janeiro';
                }

                if (month === '02') {
                  mes = 'Fevereiro';
                }

                if (month === '03') {
                  mes = 'Março';
                }

                if (month === '04') {
                  mes = 'Abril';
                }

                if (month === '05') {
                  mes = 'Maio';
                }

                if (month === '06') {
                  mes = 'Junho';
                }

                if (month === '07') {
                  mes = 'Julho';
                }

                if (month === '08') {
                  mes = 'Agosto';
                }

                if (month === '09') {
                  mes = 'Setembro';
                }

                if (month === '10') {
                  mes = 'Outubro';
                }

                if (month === '11') {
                  mes = 'Novembro';
                }

                if (month === '12') {
                  mes = 'Dezembro';
                }
                return (
                  <a className="card" href={`/blog/${post.slug}`} key={post.id}>
                    <div className="imagem">
                      <img
                        alt={
                          !!post._embedded &&
                          post._embedded['wp:featuredmedia'][0].alt_text
                        }
                        src={
                          !!post._embedded &&
                          post._embedded['wp:featuredmedia'][0].media_details
                            .sizes.thumbnail.source_url
                        }
                      />
                    </div>
                    <div className="text">
                      <p className="data">
                        {day} de {mes} de {year}
                      </p>
                      <h3>{post.title.rendered}</h3>
                      {ReactHtmlParser(post.excerpt.rendered)}
                    </div>
                    <button type="button">Continuar lendo</button>
                  </a>
                );
              })}
          </div>
          <button
            type="button"
            onClick={() => setNumerodeposts(numerodeposts + 6)}
            className={`mais ${notworking ? 'dontdisplay' : ''}`}
          >
            Carregar mais ...
          </button>
        </Noticias>
        <Footer />
      </Container>
    </>
  );
}
