/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
import { Carousel } from 'antd';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Lightbox, { Modal, ModalGateway } from 'react-images';

import {
  Container,
  Banner,
  Unique,
  Prontos,
  Sobre,
  Blog,
  Loading,
} from './styles';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

// api
import api from '../../services/api';

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [sobrenos, setSobrenos] = useState();
  const [prontos, setProntos] = useState();
  const [unique, setUnique] = useState();
  const [empreendimentos, setEmpreendimentos] = useState();
  const [posts, setPosts] = useState();
  const [modalisopen, setModalIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const bannerRef = useRef();
  const uniqueBanner = useRef();
  let index = -1;

  const bannersSettings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
  };

  const uniqueSettings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    className: 'center',
    centerMode: true,
    centerPadding: '200px',
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 1000,
        settings: {
          centerPadding: '50px',
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '25px',
        },
      },
    ],
  };

  const images =
    !!unique &&
    !!unique.acf.galeria &&
    unique.acf.galeria.map((foto) => ({
      index: ++index,
      src: foto.sizes.large,
    }));

  useEffect(() => {
    setLoading(true);

    api.get('pages?slug=sobre-nos').then((response) => {
      setSobrenos(response.data[0]);
    });

    api.get('empreendimentos').then((response) => {
      setEmpreendimentos(response.data);
      setLoading(false);
    });

    api.get('empreendimentos?categories=2').then((response) => {
      response.data.map((res) => {
        if (res.slug === 'unique') {
          setUnique(res);
        }
      });
    });

    api.get('empreendimentos?categories=3').then((response) => {
      setProntos(response.data);
    });

    api.get('posts?_embed&per_page=3').then((response) => {
      setPosts(response.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>M.M. Milléo Incorporações</title>
        <meta
          name="description"
          content=" A M.M.Milléo Construções Civis e Incorporações Imobiliárias ltda é responsável pelas atividades que abrangem desde a escolha dos melhores terrenos até o atendimento pós-venda, passando pelo desenvolvimento dos projetos, construção, incorporação e vendas."
        />
        <meta
          name="keywords"
          content="marca, campanha, site, impresso, facebook, identidade visual, logo"
        />
        <meta
          property="og:image"
          content={require('../../assets/LogoMMM.png')}
        />
      </Helmet>
      <Header />
      <Loading className={loading ? '' : 'disabled'}>
        <div id="preloader_1">
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </Loading>
      <Container>
        <Banner>
          <div id="btns">
            <button
              id="preview-btn"
              type="button"
              onClick={() => bannerRef.current.prev()}
            >
              <IoIosArrowBack />
            </button>
            <button
              id="next-btn"
              type="button"
              onClick={() => bannerRef.current.next()}
            >
              <IoIosArrowForward />
            </button>
          </div>
          <Carousel ref={bannerRef} {...bannersSettings} effect="fade">
            {!!empreendimentos &&
              empreendimentos.map((empre) => (
                <div className="slider" key={Math.random()}>
                  <div className="infos">
                    {!!empre.acf.logo && (
                      <div className="imagem-logo">
                        <img
                          alt={empre.acf.logo.alt}
                          src={empre.acf.logo.sizes.thumbnail}
                        />
                      </div>
                    )}
                    <h2>{empre.acf.slogan.toUpperCase()}</h2>
                    <p>{empre.acf.descricao}</p>
                    {empre.slug === 'unique' && (
                      <a href={`/empreendimentos/${empre.slug}`}>Conheça</a>
                    )}
                  </div>
                  <div className="img">
                    <img
                      alt={empre.acf.imagem_principal.alt}
                      src={empre.acf.imagem_principal.sizes.large}
                    />
                    <div className="bg" />
                  </div>
                </div>
              ))}
          </Carousel>
        </Banner>
        <Unique>
          <div className="btns">
            <button type="button" onClick={() => uniqueBanner.current.prev()}>
              Anterior
            </button>
            <button type="button" onClick={() => uniqueBanner.current.next()}>
              Próximo
            </button>
          </div>
          <div className="white-box">
            <h2>Unique</h2>
            <p>Qualidade desde o projeto até os últimos acabamentos.</p>
            <p className="projecoes">Projeções</p>
          </div>
          <Carousel ref={uniqueBanner} {...uniqueSettings}>
            {!!unique &&
              unique.acf.galeria.map((foto, index) => (
                <div className="slider" key={Math.random()}>
                  <div
                    className="img"
                    onClick={() => {
                      setModalIsOpen(!modalisopen);
                      setSelectedIndex(index);
                    }}
                  >
                    <img src={foto.sizes.medium} alt={foto.alt} />
                  </div>
                  <p>{foto.title}</p>
                </div>
              ))}
          </Carousel>
        </Unique>
        <Prontos id="empreendimentos">
          <h2>Prontos para morar</h2>
          <p>Qualidade desde o projeto até os últimos acabamentos.</p>
          <div className="cards">
            {!!prontos &&
              prontos.map((pronto) => (
                <div key={pronto.id}>
                  <div className="img">
                    <img
                      src={pronto.acf.imagem_principal.sizes.thumbnail}
                      alt={pronto.acf.imagem_principal.alt}
                    />
                  </div>
                  <div className="informacoes">
                    <div className="text">
                      <h4>{pronto.title.rendered}</h4>
                      <p>{pronto.acf.descricao}</p>
                    </div>
                    <div className="box">
                      <div className="infos">
                        <div>
                          <img
                            src={require('../../assets/double-bed.svg')}
                            alt=""
                          />
                          <p>{pronto.acf.quantidade_de_quartos}</p>
                        </div>
                        <div>
                          <img src={require('../../assets/scale.svg')} alt="" />
                          <p>{pronto.acf.metragem}</p>
                        </div>
                      </div>
                      <div className="endereco">
                        <img
                          src={require('../../assets/placeholder.svg')}
                          alt=""
                        />
                        <p>{pronto.acf.localizacao}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Prontos>
        <Sobre id="sobrenos">
          <div className="bg" />
          <div className="bg-img">
            <img
              alt="Centro de cidade"
              src={require('../../assets/sobre.png')}
            />
          </div>
          <div className="text">
            <h2>Olá, nós somos M.M.Milléo Incorporações Ltda</h2>
            {ReactHtmlParser(!!sobrenos && sobrenos.content.rendered)}
            <div className="line" />
          </div>
        </Sobre>
        <Blog id="blog">
          <h2>Blog</h2>
          <div className="line" />
          <div className="cards">
            {!!posts &&
              posts.map((post) => {
                const day = post.date.substring(8, 10);
                const month = post.date.substring(5, 7);
                const year = post.date.substring(0, 4);
                let mes = '';

                if (month === '01') {
                  mes = 'Janeiro';
                }

                if (month === '02') {
                  mes = 'Fevereiro';
                }

                if (month === '03') {
                  mes = 'Março';
                }

                if (month === '04') {
                  mes = 'Abril';
                }

                if (month === '05') {
                  mes = 'Maio';
                }

                if (month === '06') {
                  mes = 'Junho';
                }

                if (month === '07') {
                  mes = 'Julho';
                }

                if (month === '08') {
                  mes = 'Agosto';
                }

                if (month === '09') {
                  mes = 'Setembro';
                }

                if (month === '10') {
                  mes = 'Outubro';
                }

                if (month === '11') {
                  mes = 'Novembro';
                }

                if (month === '12') {
                  mes = 'Dezembro';
                }

                return (
                  <a className="card" href={`/blog/${post.slug}`} key={post.id}>
                    <div>
                      <div className="imagem">
                        <img
                          alt={
                            !!post._embedded &&
                            post._embedded['wp:featuredmedia'][0].alt_text
                          }
                          src={
                            !!post._embedded &&
                            post._embedded['wp:featuredmedia'][0].media_details
                              .sizes.thumbnail.source_url
                          }
                        />
                      </div>
                      <div className="text">
                        <p className="data">
                          {day} de {mes} de {year}
                        </p>
                        <h3>{post.title.rendered}</h3>
                        {ReactHtmlParser(post.excerpt.rendered)}
                      </div>
                    </div>
                    <button type="button">Continuar lendo ...</button>
                  </a>
                );
              })}
          </div>
          <a className="link" href="/blog">
            Ir para o blog ...
          </a>
        </Blog>
        <Footer />
      </Container>

      {/* Gateway lightbox */}
      <ModalGateway>
        {modalisopen && (
          <Modal onClose={() => setModalIsOpen(!modalisopen)}>
            <Lightbox views={images} currentIndex={selectedIndex} />
          </Modal>
        )}
      </ModalGateway>
    </>
  );
}
