import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Loading = styled.div`
  width: 100vw;
  height: calc(100vh - 140px);
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1270px) {
    height: calc(100vh - 130px);
  }

  @media (max-width: 1000px) {
    height: calc(100vh - 110px);
  }

  @media (max-width: 550px) {
    height: calc(100vh - 98px);
  }

  @media (max-width: 500px) {
    height: calc(100vh - 80px);
  }

  #preloader_1 {
    position: absolute;
    width: 65px;
    height: 50px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 9px;
    height: 5px;
    background: #fff;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 11px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 22px;
    animation-delay: 0.4s;
  }
  #preloader_1 span:nth-child(4) {
    left: 33px;
    animation-delay: 0.6s;
  }
  #preloader_1 span:nth-child(5) {
    left: 44px;
    animation-delay: 0.8s;
  }
  @keyframes preloader_1 {
    0% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
    25% {
      height: 30px;
      transform: translateY(15px);
      background: #fff;
    }
    50% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
    100% {
      height: 5px;
      transform: translateY(0px);
      background: #fff;
    }
  }
`;

export const Banner = styled.section`
  margin: auto;
  padding: 0 20px;
  margin-top: 100px;
  width: 80%;

  @media (max-width: 750px) {
    width: 100%;
    margin-top: 50px;
  }

  h1 {
    color: #e7ddbb;
    font-size: 50px;
    font-weight: 300;
    line-height: 46px;
    margin-bottom: 60px;
    max-width: 50%;

    @media (max-width: 750px) {
      font-size: 35px;
      margin-bottom: 35px;
      line-height: 30px;
      max-width: 100%;
    }
  }

  .data {
    font-size: 15px;
    line-height: 20px;
    color: #e7ddbb;
    margin-bottom: 55px;
    display: flex;

    @media (max-width: 750px) {
      margin-bottom: 35px;
    }
  }

  img {
    width: 100%;
    height: auto;
    margin: 25px 0 50px 0;
  }

  h2 {
    color: #e7ddbb;
    font-weight: bold;
    margin: 0;
    font-size: 24px;
    line-height: 26px;
    margin: 10px 0 30px 0;

    @media (max-width: 1440px) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  p {
    color: #c3bdac;
    font-size: 18px;
    line-height: 38px;
    margin-bottom: 25px;

    @media (max-width: 1440px) {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .links {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 20px 130px 20px;
    margin-top: 100px;
    border-top: 1px solid #e7ddbb;

    @media (max-width: 750px) {
      margin-top: 60px;
      padding: 50px 20px 80px 20px;
    }

    #st-1 {
      z-index: 1 !important;
    }

    .st-btn {
      display: inline-block !important;

      img {
        margin: 0;
      }
    }

    p {
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
      color: #ffffff;
      margin: 0;
      margin-right: 20px;
    }
  }
`;

export const Relacionados = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 40px;
    color: #c3bdac;
    margin: 0;
    line-height: 52px;
    font-weight: 300;
    width: 100%;
    max-width: 1410px;

    @media (max-width: 1440px) {
      font-size: 35px;
      line-height: 40px;
      max-width: 1100px;
      padding-left: 20px;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 400px);
    grid-gap: 85px;
    margin-top: 100px;

    @media (max-width: 1440px) {
      grid-gap: 55px;
      grid-template-columns: repeat(3, 330px);
      margin-top: 40px;
    }

    @media (max-width: 1140px) {
      grid-template-columns: repeat(2, 330px);
    }

    @media (max-width: 755px) {
      grid-template-columns: repeat(1, 330px);
    }

    @media (max-width: 370px) {
      grid-template-columns: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      :hover {
        button {
          background-color: #e7ddbb;
          color: #000;
        }
      }

      .imagem {
        width: 100%;
        height: 300px;

        @media (max-width: 1440px) {
          height: 250px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .text {
        padding: 30px 30px 40px 30px;

        @media (max-width: 1440px) {
          padding: 30px 10px 40px 10px;
        }

        .data {
          font-size: 14px;
          line-height: 19px;
          color: #e7ddbb;
          margin-bottom: 20px;
        }

        h3 {
          font-size: 26px;
          font-weight: bold;
          line-height: 26px;
          color: #e7ddbb;
          margin-bottom: 15px;

          @media (max-width: 1440px) {
            font-size: 24px;
            line-height: 30px;
          }
        }

        p {
          font-size: 18px;
          line-height: 24px;
          color: #c3bdac;
          margin: 0;

          @media (max-width: 1440px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      button {
        color: #c3bdac;
        background-color: transparent;
        border: none;
        border-bottom: 4px solid #e7ddbb;
        width: 100%;
        font-size: 20px;
        line-height: 27px;
        transition: all ease 0.5s;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px 30px;

        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
`;
