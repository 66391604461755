import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Empreendimentos from '../pages/Empreendimentos';
import Blog from '../pages/Blog';
import Detalhes from '../pages/Detalhes';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/empreendimentos/:slug" exact component={Empreendimentos} />
      <Route path="/blog" exact component={Blog} />
      <Route path="/blog/:slug" exact component={Detalhes} />
    </Switch>
  );
}
